import { ApolloLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import { createAppSyncHttpLink } from './appSyncHttpLink'
import { createAppSyncSubscriptionWebsocketLink } from './appSyncSubscriptionWebSocketLink-next'

export const createAppSyncHybridLink = ({ appSyncApiUrl, getJwtToken }) =>
    ApolloLink.split(
        isSubscriptionOperation,
        createAppSyncSubscriptionWebsocketLink({ appSyncApiUrl, getJwtToken }),
        createAppSyncHttpLink({ appSyncApiUrl, getJwtToken }),
    )

const isSubscriptionOperation = ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
}
