import { ApolloLink, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

export const createAppSyncHttpLink = function ({ appSyncApiUrl, getJwtToken }) {
    const authorizationHeaderLink = setContext(async (request, previousContext) => ({
        ...previousContext,
        headers: {
            ...previousContext.headers,
            Authorization: await getJwtToken(),
        },
    }))

    return ApolloLink.concat(authorizationHeaderLink, new HttpLink({ uri: appSyncApiUrl }))
}
