import { ReservableSpaceScreen, SpaceScreenMapPinType } from 'components/organisms/Reservable'
import { SpaceScreenSeatPin } from 'components/organisms/Reservable/types'
import * as T from 'types/data.types'
import { EAvailabilityType, EReservableType, PIN_CIRCLE } from 'types/data.types'

import { checkPersonTeamAbilitySeat } from './conditions'
import { getEmployeesFromBookings } from '../containers/SpaceScreen/helpers'
import { BookingType } from '../types/common.types'

import type { Space } from 'graphql/autogenerate/schemas'

import { getHoursFromISODateString, sortBookingsByStartTime } from './'

export const convertReservables = ({
    reservables,
    employeeId,
    employeeDepartmentIDs,
    suggestedSeatID,
    startFilterTime,
    endFilterTime,
    startSpaceWorkingTime,
    endSpaceWorkingTime,
    isAdmin,
    isRepeat = false,
    isOnlyFreeSelectable = false,
    isPersonalSeatAvailableToday = false,
    isTeamSeatAvailableToday = false,
    isForToday = false,
    bookingStatus,
}: {
    reservables: Array<T.SeatPickerReservable>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    suggestedSeatID: string | null
    startFilterTime: string
    endFilterTime: string
    startSpaceWorkingTime: string
    endSpaceWorkingTime: string
    isAdmin: boolean
    isRepeat?: boolean
    isOnlyFreeSelectable?: boolean
    isPersonalSeatAvailableToday?: boolean
    isTeamSeatAvailableToday?: boolean
    isForToday?: boolean
    bookingStatus?: BookingType
}) => {
    const convertedReservables = [] as Array<ReservableSpaceScreen>

    const seatVariants = {
        bookedSeat: null as null | ReservableSpaceScreen,
        suggestedSeat: null as null | ReservableSpaceScreen,
        randomSeat: null as null | ReservableSpaceScreen,
    }

    const allBookings: Array<T.PickerReservableBooking> = []
    const bookingWithoutParking: Array<T.PickerReservableBooking> = []

    for (let i = 0; i < reservables.length; i++) {
        const reservable = reservables[i]

        let bookings = reservable?.BookingsByDateAndDayOfWeek?.items || []
        const tags = reservable?.Tags?.items.map((tag) => ({ name: tag?.Tag?.name, id: tag?.Tag?.id })) || []

        if (reservable.type === T.EReservableType.PARKING && reservable.BookingReservablesByCreatedAt) {
            bookings = reservable.BookingReservablesByCreatedAt.items.map((item) => item.booking)
        }

        const isMyBooking = bookings?.length && bookings.find((item) => item?.Employee?.id === employeeId)

        if (reservable.type !== EReservableType.PARKING) {
            bookingWithoutParking.push(...bookings)
        }

        allBookings.push(...bookings)

        let typeSeat: SpaceScreenSeatPin = {
            pinType: SpaceScreenMapPinType.FREE_FULL,
            isOnlyBlockedTime: false,
        }

        if (isRepeat) {
            typeSeat = getTypeRepeatSpaceMapPin({
                reservable,
                employeeId,
                employeeDepartmentIDs,
                isPersonalSeatAvailableToday,
                isTeamSeatAvailableToday,
                isForToday: false,
            })
        } else {
            typeSeat = getTypeSpaceScreenMapPin({
                reservable,
                bookings,
                employeeId,
                employeeDepartmentIDs,
                startFilterTime,
                endFilterTime,
                isPersonalSeatAvailableToday,
                isTeamSeatAvailableToday,
                isForToday,
            })
        }

        let noSelectable = false

        if (isOnlyFreeSelectable) {
            noSelectable = !isMyBooking && typeSeat.pinType !== SpaceScreenMapPinType.FREE_FULL
        } else {
            noSelectable =
                typeSeat.pinType === SpaceScreenMapPinType.BLOCKED ||
                typeSeat.pinType === SpaceScreenMapPinType.REPEAT_FULL ||
                (!isAdmin && typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_FREE) ||
                (!isAdmin && typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED)
        }

        if (reservable.type !== EReservableType.ROOM) {
            if (bookingStatus && bookingStatus === BookingType.TEAM_EVENT && reservable.type !== EReservableType.ZONE) {
                noSelectable = true
            } else {
                noSelectable = !reservable.availabilityByDatesAndTime
            }
        }

        const convertedReservable: ReservableSpaceScreen = {
            name: reservable.name,
            availabilityType: reservable.availabilityType,
            id: reservable.id,
            x: reservable.x,
            y: reservable.y,
            typeReservable: reservable.type,
            typeSeat,
            bookings,
            nameRoom: reservable.name,
            preview: reservable.availabilityPreviewByDates,
            endSpaceWorkingTime,
            startSpaceWorkingTime,
            selectable: !noSelectable,
            tags,
            zonePoints: reservable?.zonePoints,
            color: reservable?.color,
            space: (reservable?.Space as Space) || null,
            availabilityByDatesAndTime: reservable.availabilityByDatesAndTime,
        }

        if (
            reservable.type === T.EReservableType.SEAT ||
            reservable.type === T.EReservableType.ROOM ||
            reservable.type === T.EReservableType.PARKING ||
            reservable.type === T.EReservableType.ZONE
        ) {
            convertedReservables.push(convertedReservable)
        }

        //find booked seat
        if (isMyBooking) {
            seatVariants.bookedSeat = convertedReservable
        }

        //find suggested seat
        if (convertedReservable.id === suggestedSeatID) {
            seatVariants.suggestedSeat = convertedReservable
        }

        /* random seat */
        if (typeSeat.pinType === SpaceScreenMapPinType.FREE_FULL) {
            seatVariants.randomSeat = convertedReservable
        }
    }
    return {
        convertedReservables,
        suggestedConvertedSeat: seatVariants.bookedSeat || seatVariants.suggestedSeat || seatVariants.randomSeat,
        allBookings,
        bookingWithoutParking,
    }
}

const getTypeRepeatSpaceMapPin = ({
    reservable,
    employeeId,
    employeeDepartmentIDs,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
}: {
    reservable: T.SeatPickerReservable
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
}) => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    const isFree =
        (reservable?.BookingsByDateAndDayOfWeek?.items || []).filter((booking) => booking.employeeID !== employeeId)
            .length === 0
    const isOnlyBlockedTime = false

    let pinType

    switch (true) {
        case isBlocked:
            pinType = SpaceScreenMapPinType.BLOCKED
            break

        // @ts-ignore
        case isUnavailable && isFree:
            pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
            break

        // @ts-ignore
        case !isFree:
            pinType = SpaceScreenMapPinType.REPEAT_FULL
            break

        default:
            pinType = SpaceScreenMapPinType.FREE_FULL
            break
    }

    return {
        pinType,
        isOnlyBlockedTime,
    }
}

const getTypeSpaceScreenMapPin = ({
    reservable,
    bookings,
    employeeId,
    employeeDepartmentIDs,
    startFilterTime,
    endFilterTime,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
}: {
    reservable: T.SeatPickerReservable
    bookings: Array<T.PickerReservableBooking>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    startFilterTime: string
    endFilterTime: string
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
}): SpaceScreenSeatPin => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    let isFullDay = false

    if (reservable.type === EReservableType.SEAT || reservable.type === EReservableType.PARKING) {
        isFullDay = checkIsFullDayByBookings(bookings, startFilterTime, endFilterTime)
    }

    if (reservable.type === EReservableType.ROOM) {
        isFullDay = checkIsFullDayRoomPreview(reservable.availabilityPreviewByDates)
    }

    const isMultiBooking = checkIsMultiBookingSpaceScreenPin(bookings)
    const isBooking = bookings.length > 0

    const isOnlyBlockedTime = bookings.length === 1 && (bookings[0].isBlocked || false)

    let pinType

    switch (true) {
        case isBlocked:
            pinType = SpaceScreenMapPinType.BLOCKED
            break

        case isUnavailable && !isMultiBooking && !isFullDay:
            pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
            break

        case isUnavailable && isMultiBooking && !isFullDay:
            pinType = SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED
            break

        case isFullDay && isMultiBooking:
            pinType = SpaceScreenMapPinType.MULTI_BOOKED_FULL
            break

        case !isFullDay && isMultiBooking:
            pinType = SpaceScreenMapPinType.MULTI_BOOKED_PART
            break

        case isFullDay && !isMultiBooking:
            pinType = SpaceScreenMapPinType.ONE_BOOKED_FULL
            break

        case !isFullDay && !isMultiBooking && isBooking:
            pinType = SpaceScreenMapPinType.ONE_BOOKED_PART
            break

        default:
            pinType = SpaceScreenMapPinType.FREE_FULL
            break
    }

    return {
        pinType,
        isOnlyBlockedTime,
    }
}

const checkIsFullDayRoomPreview = (previewHours?: Array<PIN_CIRCLE>) => {
    if (!previewHours) {
        return false
    }

    return previewHours.every((hour) => hour === PIN_CIRCLE.FULL)
}

const checkIsFullDayByBookings = (
    bookings: Array<T.PickerReservableBooking>,
    startFilterTime: string,
    endFilterTime: string,
) => {
    if (bookings.length === 0) {
        return false
    }

    let hasFullBooking = false

    const sortBookings = sortBookingsByStartTime(bookings, true)

    //joinTime in filter tim line
    let joinHoursTime = {
        startTime: getHoursFromISODateString(sortBookings[0]?.startTime),
        endTime: getHoursFromISODateString(sortBookings[0]?.endTime),
    }

    for (let i = 0; i < sortBookings.length; i++) {
        const { isFullDay, startTime, endTime } = sortBookings[i]

        const startHoursTime = getHoursFromISODateString(startTime)
        const endHoursTime = getHoursFromISODateString(endTime)

        if (isFullDay) {
            hasFullBooking = true
            break
        }

        //check when filter time has empty part
        if (startHoursTime > joinHoursTime.endTime || endHoursTime < joinHoursTime.startTime) {
            break
        }

        //add to joinTime new part
        const start = startHoursTime < joinHoursTime.startTime ? startHoursTime : joinHoursTime.startTime
        const end = endHoursTime > joinHoursTime.endTime ? endHoursTime : joinHoursTime.endTime

        if (start <= startFilterTime && end >= endFilterTime) {
            hasFullBooking = true
            break
        } else {
            joinHoursTime = { startTime: start, endTime: end }
        }
    }

    return hasFullBooking
}

const checkIsMultiBookingSpaceScreenPin = (bookings: Array<T.PickerReservableBooking>) => {
    if (bookings.length === 0) {
        return false
    }
    const employees = getEmployeesFromBookings(bookings)

    return employees.length > 1
}
