import React, { memo } from 'react'

import DropDown, { Props as DropDownProps } from 'components/DropDown'
import { MultiselectItem } from 'types/common.types'

import { CustomDropdownContainer, Root, DropDownItem } from './style'
import { ExternalLabel } from '../CustomInputV2/styles'
import DropdownArrowIcon from '../DropdownArrowIcon'

type CustomDropdownProps<ItemType> = DropDownProps<ItemType> & {
    label?: string | null
    emptyLabel?: boolean
    withOutMargin?: boolean
}

const CustomDropdown = <ItemType extends MultiselectItem>({
    withOutMargin,
    label,
    emptyLabel,
    placeholder,
    ...props
}: CustomDropdownProps<ItemType>) => {
    const dropDownStyles = {
        borderRadius: 12,
        background: 'rgba(227, 232, 238, 255)',
        li: {
            height: '56px',
        },
    }

    return (
        <Root withOutMargin={withOutMargin}>
            {(label || emptyLabel) && <ExternalLabel empty={emptyLabel}>{label || 'empty'}</ExternalLabel>}
            <CustomDropdownContainer data-label="34234">
                <DropDown
                    {...props}
                    placeholder={placeholder}
                    styles={dropDownStyles}
                    IconComponent={DropdownArrowIcon}
                    DropDownItem={DropDownItem}
                />
            </CustomDropdownContainer>
        </Root>
    )
}

export default memo(CustomDropdown)
