import React, { FC, useState, useEffect, type ReactElement } from 'react'

import { CrossSVG, BuddyColorSVG } from 'assets/common'
import { useResizeDevice } from 'context'
import { useAppSelector } from 'hooks'

import * as S from './styles'

const NewFeatureBanner: FC<{ textComponent: ReactElement }> = ({ textComponent }) => {
    const { isMobile } = useResizeDevice()

    const { turnOnExperimentalFeatures } = useAppSelector(({ user }) => user)

    const statusFeature = sessionStorage.getItem('statusFeature')
    const isFeatureEnabled = statusFeature === 'true'

    const [showBlock, setShowBlock] = useState(false)

    useEffect(() => {
        if (turnOnExperimentalFeatures) {
            setShowBlock(false)
        } else {
            setShowBlock(true)
            sessionStorage.setItem('statusFeature', 'true')
        }
    }, [turnOnExperimentalFeatures])

    if (isMobile) {
        return null
    }

    const close = () => {
        sessionStorage.setItem('statusFeature', 'false')
        document.cookie = 'statusFeature=false; path=/; max-age=' + 24 * 60 * 60
        setShowBlock(false)
    }

    return (
        <>
            {showBlock && (
                <S.NewFeatureBanner className={'df align-items-center'}>
                    <BuddyColorSVG width={32} height={32} />
                    <S.NewFeatureBannerTitle className={'ml-4'}>{textComponent}</S.NewFeatureBannerTitle>

                    <S.NewFeatureBannerButtonClose onClick={close} className={'ml-8'}>
                        <CrossSVG width={16} height={16} className={'close-svg'} />
                    </S.NewFeatureBannerButtonClose>
                </S.NewFeatureBanner>
            )}
        </>
    )
}

export default NewFeatureBanner
