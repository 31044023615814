import styled from 'styled-components'

import { ButtonOutlined } from 'styles/buttons-styles'

export const NewFeatureBanner = styled.div`
    position: absolute;
    bottom: 10px;
    padding: 10px 12px 10px 16px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.color.greyBackground50};
    background: ${(props) => props.theme.color.white_80};
    background: var(--background_grey_50, rgba(226, 232, 239, 0.5));
    /* shadow+bgblur */
    box-shadow: 0px 4px 8px -5px rgba(150, 157, 164, 0.5);

    backdrop-filter: blur(12.5px);
    z-index: 28;
    left: 50%;
    transform: translateX(-40%);
    @media (min-width: 480px) and (max-width: 1090px) {
        left: 25%;
        transform: translateX(0);
    }
`
export const NewFeatureBannerTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 87%;
    color: ${(props) => props.theme.color.secondary_black_50};
    b {
        color: ${(props) => props.theme.color.black};
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }
    a {
        color: ${(props) => props.theme.color.blueButton};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-decoration-line: underline;
    }
`

export const NewFeatureBannerButtonClose = styled(ButtonOutlined)`
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 8px;
    height: auto;
    border: transparent;
    margin-left: auto;
    &:hover {
        background: ${(props) => props.theme.color.greyBackground50};
    }
    .close-svg {
        path {
            fill: #333333;
            fill-opacity: 0.5;
        }
    }
`
