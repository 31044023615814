import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { fromPromise } from '@apollo/client/link/utils'
import { Auth } from 'aws-amplify'

import { createAppSyncHybridLink } from './appSyncHybridLink'
import { getNewToken } from '../helpers/getNewToken'
import { JwtTokenName } from '../shared/constants'
import { AUTH_ROUTES } from '../types/routes'

const getJwtToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken()

const appSyncApiUrl = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || ''

type TAppSyncApolloClient = { connectToDevTools?: boolean }

const retryLink = new RetryLink({
    delay: {
        initial: 1000,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 20,
        retryIf: (error, op) => {
            console.log('RETRY-ERROR', error, op)
            return !!error
        },
    },
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            /* todo: ask back-end to add err.extensions.code */
            console.log('err', err)
            switch (err.message) {
                /* Token has expired */
                case 'Valid authorization header not provided.':
                case 'Token has expired.': {
                    return fromPromise(
                        getNewToken().catch((error) => {
                            /* redirect to login */
                            window.location.replace(AUTH_ROUTES.LOGIN)
                            return
                        }),
                    )
                        .filter((value) => Boolean(value))
                        .flatMap((newToken) => {
                            const oldHeaders = operation.getContext().headers

                            localStorage.setItem(JwtTokenName, newToken || '')

                            operation.setContext({
                                headers: {
                                    ...oldHeaders,
                                    ...(newToken ? { Authorization: newToken } : {}),
                                },
                            })

                            return forward(operation)
                        })
                }
                default: {
                    const { message, locations, path } = err
                    console.log(`[GraphQl error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                }
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError.message} `)
        if (
            networkError.message?.includes('UnauthorizedException') ||
            networkError.message?.includes('Token has expired')
        ) {
            return fromPromise(
                getNewToken().catch((error) => {
                    /* redirect to login */
                    window.location.replace(AUTH_ROUTES.LOGIN)
                    return
                }),
            )
                .filter((value) => Boolean(value))
                .flatMap((newToken) => {
                    const oldHeaders = operation.getContext().headers

                    localStorage.setItem(JwtTokenName, newToken || '')

                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            ...(newToken ? { Authorization: newToken } : {}),
                        },
                    })

                    return forward(operation)
                })
        }
    }
})
export const createAppSyncApolloClient = ({ connectToDevTools }: TAppSyncApolloClient) =>
    new ApolloClient({
        link: ApolloLink.from([retryLink, errorLink, createAppSyncHybridLink({ appSyncApiUrl, getJwtToken })]),
        cache: new InMemoryCache(),
        connectToDevTools,
    })
