// UUIDOperationIdSubscriptionClient.js

// AppSync recommends using UUIDs for Subscription IDs but SubscriptionClient uses an incrementing number
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { v4 as uuid4 } from 'uuid'

export const asBase64EncodedJson = (value) => btoa(JSON.stringify(value))

// @ts-ignore
export class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
    private authFunction
    private originalUrl

    constructor(url, args, authFunction) {
        super(url, args)
        this.authFunction = authFunction
        this.originalUrl = url
    }

    connect = async () => {
        const authInfo = await this.authFunction()

        /** @see https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html#iam */
        // @ts-ignore
        this.url = `${this.originalUrl}?header=${asBase64EncodedJson(authInfo)}&payload=${asBase64EncodedJson({})}`

        // @ts-ignore
        super.connect()
    }

    generateOperationId() {
        return uuid4()
    }

    processReceivedData(receivedData) {
        try {
            const parsedMessage = JSON.parse(receivedData)
            if (parsedMessage?.type === 'start_ack') {
                return
            }
        } catch (e) {
            throw new Error('Message must be JSON-parsable. Got: ' + receivedData)
        }

        // @ts-ignore
        super.processReceivedData(receivedData)
    }
}
