import { createSlice, PayloadAction, ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit'
import moment from 'moment'

import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import { getMobileSuggestedCalendarTime } from 'containers/Calendar/helpers'
import { CalendarScheduleBooking } from 'containers/Calendar/types'
import { Booking, Reservable, Space, RepeatType as MobileScheduleRepeatType } from 'graphql/autogenerate/schemas'
import { MainEmployeeData } from 'types/data.types'

import { fetchCurrentReservable } from './editBookingThunks'
import { getAWSFormatDate, getConvertedEngLocaleDate } from '../../../../../helpers'
import { initialStateProps } from '../types'

import type { FormValues } from 'components/shared/widgets/CalendarInfoDialog/types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

const initialState: initialStateProps = {
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    scheduleBookings: [],
    placeBookings: [],
    open: false,
    openMap: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    companyID: '',
    employeeID: '',
    currentReservable: null,
    loadingTimeLine: false,
    loadingBookingInfo: false,
    currentSpace: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(getAWSFormatDate(moment())), 'dddd')],
    selectedTeamEventMembers: [],
    selectedEmployee: null,
    checkInStatus: false,
    currentBooking: null,
    editFormValue: null,
    parkingMapStatus: false,
    currentParkingReservable: null,
    suggestedSeatID: null,
    repeatDate: [],
    isSpaceMapLoading: false,
    spaceLoader: false,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    isSeatAvailable: true,
    editBookingPopup: false,
    isEditMode: false,
    isNotAvailableSeatReason: '',
}

const editBookingSlice = createSlice({
    name: 'editBooking',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<{ companyID: string; employeeID: string }>) => {
            return {
                ...state,
                companyID: action.payload.companyID,
                employeeID: action.payload.employeeID,
            }
        },
        selectCurrentReservable: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            const filteredBookings = action?.payload?.bookings?.filter(
                (booking) => booking.employeeID !== state?.employeeID,
            ) as Booking[]
            return {
                ...state,
                currentReservable: action.payload,
                placeBookings: filteredBookings,
            }
        },

        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },
        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },
        setExistingBookings: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                scheduleBookings: action.payload,
                open: true,
                checkInStatus: true,
            }
        },

        setSpace: (state, action: PayloadAction<Space>) => {
            return {
                ...state,
                startWorkTime: action.payload.workingHoursFrom,
                endWorkTime: action.payload.workingHoursTo,
                currentSpace: action.payload,
            }
        },
        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },
        setLoadingTimeLine: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingTimeLine: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                spaceLoader: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },

        setCheckInStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                checkInStatus: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },
        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },
        setTeamEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedTeamEventMembers: action.payload,
            }
        },
        setCurrentBooking: (state, action: PayloadAction<Booking | null>) => {
            const timeStart = moment(action.payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment(action.payload?.endTime || state.endWorkTime).format('HH:mm')
            return {
                ...state,
                currentBooking: action.payload,
                selectedBookingTime: [timeStart, timeEnd],
            }
        },

        setFormEdit: (state, action: PayloadAction<FormValues>) => {
            return {
                ...state,
                editFormValue: action.payload,
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        setMapStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                openMap: action.payload,
            }
        },

        setEditBookingPopup: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                editBookingPopup: action.payload,
            }
        },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setBookingFromPlace: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                placeBookings: action.payload,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        /*Parking*/
        selectCurrentReservableParking: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentParkingReservable: action.payload,
            }
        },

        setParkingMap: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder: ActionReducerMapBuilder<initialStateProps>) => {
        builder
            .addCase(fetchCurrentReservable.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                }
            })
            .addCase(
                fetchCurrentReservable.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Reservable>) => {
                    return {
                        ...state,
                    }
                },
            )
            .addCase(fetchCurrentReservable.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                }
            })
    },
})

export const editBookingActions = editBookingSlice.actions
export default editBookingSlice.reducer
