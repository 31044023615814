import { createAsyncThunk } from '@reduxjs/toolkit'

import { Reservable } from 'graphql/autogenerate/schemas'
import { GET_RESERVABLE_BY_ID } from 'graphql/queries'
import { getErrorMessage } from 'helpers'
import { messageActions } from 'store/slices/message'

export const fetchCurrentReservable = createAsyncThunk<Reservable, { reservableID: string }, { extra: any }>(
    'editBooking/fetchCurrentReservable',
    async ({ reservableID }, thunkAPI) => {
        const { client } = thunkAPI.extra
        try {
            const response = await client.query({
                query: GET_RESERVABLE_BY_ID,
                variables: {
                    reservableId: reservableID,
                },
                fetchPolicy: 'no-cache',
            })
            return response.data.getReservable
        } catch (err) {
            thunkAPI.dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            return thunkAPI.rejectWithValue(getErrorMessage(err))
        }
    },
)
