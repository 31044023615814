import styled from 'styled-components'

import { ButtonOutlined } from '../../../styles/buttons-styles'

export const HeaderRoot = styled.header`
    display: flex;
    align-items: center;
    padding: 16px 24px 16px 32px;
    background-color: ${(props) => props.theme.color.white};
    height: 120px;
    z-index: 1;
    justify-content: space-between;
    position: sticky;
    left: 0;
    z-index: 14;
    top: 0;
`

export const LeftSideWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`
export const CalendarHeaderTitleWrapp = styled.div`
    width: 303px;
    flex: 1;
    display: flex;
    margin-right: 12px;
`

export const HeaderInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 2.5;
`

export const HeaderDateInfo = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`

export const HeaderTitle = styled.h2`
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: ${(props) => props.theme.color.black};
    margin: 0;
`

export const HeaderDate = styled.h3`
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
`

export const AdminPanelLink = styled(ButtonOutlined)`
    padding: 24px;

    border-radius: 20px;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 6px;
    }
`
