import {
    DemoAccount,
    Department,
    ENotificationChannel,
    ERoleEmployee,
    Office,
    Position,
    TDepartment,
} from './data.types'
import { ICognitoUserAttributes } from './override/amazon-cognito-identity-js'

export enum EUserGroup {
    MANAGER = 'manager',
    EMPLOYEE = 'employee',
    TEAM_LEAD = 'teamlead',
}

export enum EUserLocale {
    EN = 'en',
    DE = 'de',
    FR = 'fr',
}

export enum EUserShareAttendance {
    ALL = 'ALL',
    TEAM = 'TEAM',
    ADMIN = 'ADMIN',
    NONE = 'NONE',
}

export type TUser = {
    firstName: string
    lastName: string
    email: string
    id: string
    companyId: string
    employeeId: string
}

export type TUserAttributes = {
    isCompanyCreated: boolean
    isProfileConfirmed: boolean
}

export type TCustomUserAttributes = Pick<
    ICognitoUserAttributes,
    'custom:isCompanyCreated' | 'custom:isProfileConfirmed'
>

export type TSignUpData = {
    username: string
    password: string
    attributes: ICognitoUserAttributes
}

//new
type CommonUserType = {
    id: string
    firstname: string
    favouriteOfficeID: string
    lastname: string
    cognitoUserId: string
    email: string
    photo: null | string
    role: ERoleEmployee
    locale: EUserLocale
}

export type UserResponse = CommonUserType & {
    // departments: Array<TDepartment>
    departmentIDs: Array<string>
    departmentsString: string
    favouriteColleagueIDs?: Array<string>
    favouriteReservableID?: string
    Position: Position
    Office: Office
    notificationChannels: Array<ENotificationChannel> | null
    shareAttendance: EUserShareAttendance | null
    demoAccountID: string | null
    buddyID?: string
    turnOnExperimentalFeatures: boolean
}

export type User = CommonUserType & {
    fullName: string
    // departments: TDepartment[]
    departmentIDs: UserResponse['departmentIDs']
    buddyID: UserResponse['buddyID']
    favouriteColleagueIDs: UserResponse['favouriteColleagueIDs']
    favouriteReservableID: UserResponse['favouriteReservableID']
    position: UserResponse['Position']
    office: UserResponse['Office']
    demoAccountID: UserResponse['demoAccountID']
    notificationChannel: ENotificationChannel
    shareAttendance: EUserShareAttendance
    isAdmin: boolean
    turnOnExperimentalFeatures: boolean
}
